import type { AppProps } from "next/app";
import Script from "next/script";
import { SWRConfig } from "swr";

import WithCookieBannerLayout from "@/layouts/WithCookieBanner";
import WithSurveyLayout from "@/layouts/WithSurvey";

import "../styles/main.scss";
import Head from "next/head";

function MyApp({ Component, pageProps }: AppProps) {
	let GTM = { auth: "J8br2ZcPkXtHTHNT21vB3w", id: "GTM-TMW5TL9", env: "1" };
	if (process.env.NODE_ENV.toUpperCase() !== "PRODUCTION") {
		GTM = { auth: "2G6-U8-6MsOPeFfST2IvZQ", id: "GTM-TMW5TL9", env: "3" };
	}
	return (
		<>
			<Head>
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta
					name="google-site-verification"
					content="qwNzC5m8qNzkDxdZckfyBdLNNRGwvhFWgQaekSIaDKk"
				/>
				<meta
					name="google-site-verification"
					content="WCwG8yR6f0DLZI6-gH9-Q-OvEVy_MzAGQIony3QQIc0"
				/>
				<meta
					name="p:domain_verify"
					content="029e119a88b6561fb7d66ad8e08baefd"
				/>
			</Head>
			{/* <!-- Google Tag Manager --> */}
			<Script
				id="google-tag-manager"
				strategy="afterInteractive"
			>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
			new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
			j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
			'https://www.googletagmanager.com/gtm.js?id='+i+dl+'&gtm_auth=${GTM.auth}&gtm_preview=env-${GTM.env}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
			})(window,document,'script','dataLayer','${GTM.id}');`}</Script>
			{/* <!-- End Google Tag Manager --> */}
			<SWRConfig value={{ revalidateIfStale: false, refreshInterval: 30_000 }}>
				<WithCookieBannerLayout>
					<WithSurveyLayout>
						<Component {...pageProps} />
					</WithSurveyLayout>
				</WithCookieBannerLayout>
			</SWRConfig>
			<Script
				id="MauticSDK"
				strategy="beforeInteractive"
			>{`/** This section is only needed once per page if manually copying **/
			if (typeof MauticSDKLoaded == 'undefined') {
					var MauticSDKLoaded = true;
					var head            = document.getElementsByTagName('head')[0];
					var script          = document.createElement('script');
					script.type         = 'text/javascript';
					script.src          = 'https://wesharewine.captuble.com/media/js/mautic-form.js?v70e96c7c';
					script.onload       = function() {
							MauticSDK.onLoad();
					};
					head.appendChild(script);
					var MauticDomain = 'https://wesharewine.captuble.com';
					var MauticLang   = {
							'submittingMessage': "Please wait..."
					}
			}else if (typeof MauticSDK != 'undefined') {
					MauticSDK.onLoad();
			}`}</Script>
			{/* <!-- Google Tag Manager (noscript) --> */}
			<noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM.id}&gtm_auth=${GTM.auth}&gtm_preview=env-${GTM.env}" height="0" width="0" style="display: none; visibility: hidden;" />`}</noscript>
			{/* <!-- End Google Tag Manager (noscript) --> */}
		</>
	);
}

export default MyApp;
