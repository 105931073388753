import {
	Questionnaire,
	Question,
	Result,
	OtherFields,
} from "@/models/questionnaire";
import { GenericID } from "@/models/api";
import { Wine, HomePage, WP_Image } from "@/models";
import { ApiError, clientFetcher } from "@/utils/fetcher";
import useSWR, { KeyedMutator } from "swr";
import useSWRImmutable from "swr/immutable";
import { WC_Order } from "@/models/orders";
import { Menu, WP_Navigation } from "@/models/menu";
import { IPaymentMethod } from "@/models/payments";
import { useEffect, useState } from "react";
import { Acf, Survey } from "@/models/survey";

type ApiResponse<T> = [T | undefined, boolean, ApiError | undefined];
type ApiResponseWithMutate<T> = [
	T | undefined,
	boolean,
	ApiError | undefined,
	KeyedMutator<T>
];

export function useHome() {
	const { data, error } = useSWR<HomePage, ApiError>(
		"/wsw/v1/front",
		clientFetcher
	);

	return {
		data,
		isLoading: !error && !data,
		isError: error,
	};
}

export function useImage(id?: GenericID | false): ApiResponse<WP_Image> {
	const { data, error } = useSWR<WP_Image, ApiError>(
		id ? `/wp/v2/media/${id}` : null,
		clientFetcher
	);

	return [data, !error && !data, error];
}

export function useWines(ids?: GenericID[]): ApiResponse<Wine[]> {
	const { data, error } = useSWR<Wine[], ApiError>(
		ids ? "/wsw/v1/wine?include=" + ids?.join(",") : null
	);

	return [data, !error && !data, error];
}

export function useWine(slug?: string): ApiResponse<Wine> {
	const { data, error } = useSWR<Wine, ApiError>(
		slug ? `/wsw/v1/wine/${slug}` : null,
		clientFetcher
	);

	return [data, !error && !data, error];
}

export function useQuestionnaire(): ApiResponse<Questionnaire> {
	const { data, error } = useSWR<Questionnaire, ApiError>(
		"/wsw/v1/questionnaire",
		clientFetcher
	);

	return [data, !error && !data, error];
}

export function useQuestions(): ApiResponse<Question[]> {
	const { data, error } = useSWR<Question[], ApiError>(
		"/wp/v2/question",
		clientFetcher
	);

	return [data, !error && !data, error];
}

export function useQuestion(slug?: string): ApiResponse<Question> {
	const { data, error } = useSWR<Question, ApiError>(
		slug ? `/wsw/v1/question/${slug}` : null,
		clientFetcher
	);

	return [data, !error && !data, error];
}

export function useResult(slug?: string): ApiResponse<Result> {
	const { data, error } = useSWR<Result, ApiError>(
		slug ? `/wsw/v1/drinkmoment/${slug}` : null,
		clientFetcher
	);

	return [data, !error && !data, error];
}

export function useOtherFields(): ApiResponse<OtherFields> {
	const { data, error } = useSWR<OtherFields, ApiError>(
		"/wsw/v1/other-fields",
		clientFetcher
	);

	return [data, !error && !data, error];
}

export function useOrder(orderId?: GenericID): ApiResponseWithMutate<WC_Order> {
	const customFetcher = (input: RequestInfo, init: RequestInit) =>
		fetch(input, init).then((r) => r.json());

	const { data, error, mutate } = useSWR<WC_Order, ApiError>(
		orderId ? `/api/order?id=${orderId}` : null,
		customFetcher
	);

	return [data, !error && !data, error, mutate];
}

function getSessionStorageOrDefault<T>(key: string, defaultValue: T) {
	const stored = sessionStorage.getItem(key);
	if (!stored) {
		return defaultValue;
	}
	return JSON.parse(stored);
}

export function useSessionStorage<T>(key: string, defaultValue: T) {
	const [value, setValue] = useState(
		getSessionStorageOrDefault(key, defaultValue)
	);

	useEffect(() => {
		sessionStorage.setItem(key, JSON.stringify(value));
	}, [key, value]);

	return [value, setValue];
}

export function usePaymentMethods(): ApiResponse<IPaymentMethod[]> {
	const customFetcher = (input: RequestInfo, init: RequestInit) =>
		fetch(input, init).then((r) => r.json());

	// because after fetching the payment methods, they won't change we can use the immutable SWR so we don't re-fetch the data.
	const { data, error } = useSWRImmutable<IPaymentMethod[], ApiError>(
		"/api/payment/methods",
		customFetcher
	);

	return [data, !error && !data, error];
}

export function useNavigation(): ApiResponse<WP_Navigation> {
	const { data, error } = useSWRImmutable<WP_Navigation, ApiError>(
		"/wsw/v1/menu/",
		clientFetcher
	);

	return [data, !error && !data, error];
}

export function usePrimaryMenu(): ApiResponse<Menu> {
	const { data, error } = useSWRImmutable<Menu, ApiError>(
		"/wsw/v1/menu/primary-menu",
		clientFetcher
	);

	return [data, !error && !data, error];
}

export function useSecondaryMenu(): ApiResponse<Menu> {
	const { data, error } = useSWRImmutable<Menu, ApiError>(
		"/wsw/v1/menu/secondary-menu",
		clientFetcher
	);

	return [data, !error && !data, error];
}

export function useIconMenu(): ApiResponse<Menu> {
	const { data, error } = useSWRImmutable<Menu, ApiError>(
		"/wsw/v1/menu/icon-menu",
		clientFetcher
	);

	return [data, !error && !data, error];
}

export function useSurvey(shouldFetch: boolean): ApiResponse<Acf<Survey>> {
	const { data, error } = useSWRImmutable<Acf<Survey>, ApiError>(
		shouldFetch ? "/wsw/v1/survey" : null,
		clientFetcher
	);

	return [data, !error && !data, error];
}
