import create from "zustand";
import { persist } from "zustand/middleware";

interface SurveyState {
	hiddenByUser: boolean;
	setHiddenByUser: (value: boolean) => void;
}

export const useSurveyStore = create<SurveyState>(
	persist(
		(set, _) => ({
			hiddenByUser: false,
			setHiddenByUser: (value: boolean) => set({ hiddenByUser: value }),
		}),
		{
			name: "survey-storage",
			getStorage: () => sessionStorage,
		}
	)
);
