import { useRef, PropsWithChildren } from "react";
import dynamic from "next/dynamic";
// import CookieBanner from "@/components/CookieBanner/CookieBanner";

const DynamicCookieBanner = dynamic(
	() => import("@/components/CookieBanner/CookieBanner"),
	{
		ssr: false,
	}
);

export default function WithCookieBannerLayout({
	children,
}: PropsWithChildren<{}>) {
	const portalRef = useRef<HTMLDivElement | null>(null);

	return (
		<div id="withCookieBannerLayout">
			<div id="cookie-container" ref={portalRef}>
				<DynamicCookieBanner portalRef={portalRef} />
			</div>
			{children}
		</div>
	);
}
