import { encode } from "js-base64";

export async function fetcher<ResponseBody>(
	input: string,
	init?: RequestInit
): Promise<ResponseBody> {
	const response = await fetch(input, init);

	await handleErrorResponse(response);

	return response.headers.get("content-type")?.includes("application/json")
		? (response.json() as Promise<ResponseBody>)
		: ({} as ResponseBody);
}

export async function clientFetcher<ResponseBody>(
	input: string,
	init?: RequestInit
): Promise<ResponseBody> {
	const baseUrl = getApiUrl(input);

	const authToken = encode(
		process.env.NEXT_PUBLIC_WP_AUTH_USER! +
			":" +
			process.env.NEXT_PUBLIC_WP_AUTH_PW!
	);

	const initWithToken: RequestInit = {
		...init,
		headers: {
			...init?.headers,
			Authorization: `Basic ${authToken}`,
		},
	};

	return await fetcher(baseUrl.href, initWithToken);
}

export async function serverFetcher<ResponseBody>(
	input: string,
	init?: RequestInit
): Promise<ResponseBody> {
	const baseUrl = getApiUrl(input);

	const authToken = encode(
		process.env.WC_AUTH_USER! + ":" + process.env.WC_AUTH_PW!
	);

	const initWithToken: RequestInit = {
		...init,
		headers: {
			...init?.headers,
			Authorization: `Basic ${authToken}`,
		},
	};
	return await fetcher(baseUrl.href, initWithToken);
}

async function handleErrorResponse(response: Response): Promise<void> {
	if (!response.ok) {
		const { data, message } = (await response.json()) as {
			code: string;
			message: string;
			data: {
				status: number;
			};
		};
		throw new ApiError(data.status.toString(), message);
	}
}

function getApiUrl(input: string) {
	return new URL("wp-json" + input, process.env.NEXT_PUBLIC_API_URL);
}

export class ApiError extends Error {
	constructor(public readonly code: string, message: string) {
		super(message);
		this.name = "ApiError";
	}
}
