// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (
	process.env.NODE_ENV === "PRODUCTION" ||
	process.env.NODE_ENV === "STAGING"
) {
	Sentry.init({
		environment: process.env.ENV,
		server_name: `Wesharewine ${process.env.ENV}`,
		dsn: SENTRY_DSN,
	});
}
