import { PropsWithChildren, useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
import { AnimatePresence, domAnimation, LazyMotion } from "framer-motion";
import { useSurvey } from "@/api/hooks";
import { useSurveyStore } from "@/hooks/use-survey-store";

const DynamicSurveyDialog = dynamic(
	() => import("@/components/Dialogs/QuestionDialog"),
	{
		ssr: false,
	}
);

export default function WithSurveyLayout({ children }: PropsWithChildren<{}>) {
	const surveyStore = useSurveyStore();
	const [data] = useSurvey(!surveyStore.hiddenByUser);
	const [showSurvey, setShowSurvey] = useState<boolean | null>(null);
	const portalRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (surveyStore.hiddenByUser === false && data?.acf.show_survey_dialog) {
			setTimeout(() => {
				setShowSurvey(data?.acf.show_survey_dialog);
			}, 1500);
		}
	}, [data, surveyStore.hiddenByUser]);

	return (
		<>
			<div id="survey-container" ref={portalRef}>
				<LazyMotion features={domAnimation}>
					<AnimatePresence>
						{showSurvey && data && (
							<DynamicSurveyDialog
								title={data?.acf.title}
								text={data?.acf.content}
								button={data?.acf.button}
								buttonAction={data?.acf.button.url}
								isOpen={true}
								portalRef={portalRef}
								onClose={() => {
									surveyStore.setHiddenByUser(true);
									setShowSurvey(false);
								}}
							/>
						)}
					</AnimatePresence>
				</LazyMotion>
			</div>
			{children}
		</>
	);
}
